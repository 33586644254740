/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SVGInline from "react-svg-inline"
import lightPurpleDot from "!raw-loader!svgo-loader!../../assets/light-purple-dot.svg"

const styles = {
  post: {
    paddingTop: 4,
    paddingBottom: 4,
    textDecoration: `none`,
    maxWidth: 400,
    transition: t => t.transitions.default,
    "&:hover": {
      ml: 3,
    },
    "&:hover > :nth-child(2) > :last-child": {
      borderBottom: t => `1px solid ${t.colors.purple[60]}`,
    },
  },
  title: {
    textDecoration: `none`,
    marginBottom: 2,
    fontSize: `120%`,
    fontWeight: 500,
    position: `relative`,
  },
  author: {
    mb: 0,
    color: `text.primary`,
  },
  authorName: {
    borderBottom: t => `1px solid ${t.colors.link.border}`,
    textDecoration: `none`,
    transition: t => t.transitions.default,
    ":hover": {
      borderColor: `link.hoverBorder`,
    },
  },
  readMore: {
    fontWeight: `bold`,
    borderBottom: t => `1px solid ${t.colors.link.border}`,
    textDecoration: `none`,
    transition: t => t.transitions.default,
    ":hover": {
      borderColor: `link.hoverBorder`,
    },
  },
}

const BlogPost = ({ post }) => {
  const avatarSrc = post.author.node.authorInfo.avatar?.gatsbyImage
  return (
    <Link to={`/blog/${post.slug}`} key={post.id} sx={styles.post}>
      {post.useCasesInfo.caseStudyLogo ? (
        <GatsbyImage
          sx={{ mb: 4 }}
          image={post.useCasesInfo.caseStudyLogo.gatsbyImage}
          alt=""
        />
      ) : (
        <div sx={{ mb: 4, height: 0 }} />
      )}
      <p sx={styles.title}>
        <span
          sx={{
            verticalAlign: `middle`,
            position: `absolute`,
            left: -25,
            top: `3px`,
          }}
        >
          <SVGInline svg={lightPurpleDot} />
        </span>
        <span>
          {post?.useCasesInfo?.shortTitle
            ? post?.useCasesInfo?.shortTitle
            : post.title}
        </span>
      </p>
      <p sx={styles.author}>
        {avatarSrc ? (
          <GatsbyImage
            src={avatarSrc}
            alt={post.author.node.name}
            width={24}
            height={24}
            sx={styles.avatar}
          />
        ) : null}
        <span sx={{ fontWeight: 500 }}>{post.author.node.name}</span>,{" "}
        {post.author.node.authorInfo.formalTitle
          ? post.author.node.authorInfo.formalTitle
          : post.author.node.authorInfo.bio}
      </p>
    </Link>
  )
}

export default BlogPost
